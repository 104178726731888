import ApplicationController from "../controllers/application_controller";
export default class extends ApplicationController {
  static values = {
    source: String,
    destination: String
  };
  connect() {
    this.element.href = `/website_click?source=${this.sourceValue}&destination=${this.destinationValue}`;
  }
}
