import {ApplicationController} from "stimulus-use";

export default class extends ApplicationController {
  get camelCasedIdentifier() {
    // https://leastbad.com/stimulus-power-move
    return (str => {
      return str
        .split("--")
        .slice(-1)[0]
        .split(/[-_]/)
        .map(w => w.replace(/./, m => m.toUpperCase()))
        .join("")
        .replace(/^\w/, c => c.toLowerCase());
    })(this.identifier);
  }

  attach() {
    // https://leastbad.com/stimulus-power-move
    this.element[this.camelCasedIdentifier] = this;
    // console.log(`attaching ${this.camelCasedIdentifier}`);
  }

  // a custom version of dispatch that just uses a name (unprefixed)
  simpleDispatch(eventName, detail = {}) {
    // includes the emitting controller in the event detail
    Object.assign(detail, {controller: this});
    const event = new CustomEvent(eventName, {detail, bubbles: true, cancelable: true});
    this.element.dispatchEvent(event);
    return event;
  }

  hidden() {
    return this._hiddenElement(this.element);
  }

  _hiddenElement(e) {
    if (e.hidden) {
      return true;
    } else if (!e.parentElement) {
      return false;
    } else {
      return this._hiddenElement(e.parentElement);
    }
  }
}
