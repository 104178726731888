import {Controller} from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["cookieConsentBanner"];
  connect() {
    if(localStorage.getItem('consentMode') === null) { // Haven't accepted or rejected yet
      this.showCookieConsentBanner();
    }
  }
  showCookieConsentBanner() {
    this.cookieConsentBannerTarget.style.maxHeight = "500px";
  }
  acceptAnalyticsCookies(event) {
    this.setConsent({
      analytics_storage: 'granted'
    });
    this.hideBanner();
  }
  rejectAnalyticsCookies(event) {
    this.setConsent({
      analytics_storage: 'denied'
    });
    this.hideBanner();
  }
  setConsent(consent) {
    gtag('consent', 'update', consent);
    localStorage.setItem('consentMode', JSON.stringify(consent));
  }
  hideBanner() {
    this.cookieConsentBannerTarget.style.maxHeight = "0";
  }
}
